import React from "react";
import Archive from "src/components/Archives/Archive";
import Layout from "src/components/Layout";
import { graphql } from "gatsby";
import { PageContext } from "src/utils/context";

export default class ArchiveTemplate extends React.Component {
  render() {
    return (
        <PageContext.Provider
            value={{
                hasHero: false,
                currentPage: '',
                location: {
                    pathname: 'news/',
                    search: 'news/'
                }
            }}
        >
            <Layout location={this.props.location}>
                <Archive posts={this.props.data.posts.edges} numPages={this.props.pageContext.numPages} currentPage={this.props.pageContext.currentPage} />
            </Layout>
        </PageContext.Provider>
    );
  }
}

export const query = graphql`
    query getAllNews($skip: Int!, $limit: Int!) {
        posts: allStoryblokEntry(
            limit: $limit
            skip: $skip
            filter: {field_component: {eq: "news_article"}}
            sort: {order: DESC, fields: first_published_at}
        ) {
            edges {
                node {
                    name
                    content
                    full_slug
                    uuid
                    first_published_at
                    tag_list
                }
            }
        }
    }
`;
